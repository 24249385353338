var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"day-view"},[(!_vm.isWeek)?_c('h3',{staticClass:"week-day"},[_vm._v(_vm._s(_vm.getWeekDay))]):_vm._e(),_c('article',{staticClass:"schedule-box",class:{
      'is-date-box': !_vm.isWeek
    }},[(!_vm.isWeek)?_c('AllDay',{attrs:{"list":_vm.todoList.allDayList}}):_vm._e(),_c('article',{ref:"timeMain",staticClass:"schedule-main",class:{
        'is-date': !_vm.isWeek
      }},[_c('ul',{staticClass:"table"},[(!_vm.isWeek)?_vm._l((_vm.timeList),function(item){return _c('li',{key:item.time,staticClass:"hour",class:{
              'is-target': item.isTarget,
              'on-hour': item.time.indexOf('00') !== -1
            },style:({
              top: item.isTarget ? (-1 * (60 - item.minutes) / 60) * 56 + 'px': 0
            }),attrs:{"id":item.time}},[(!_vm.isWeek)?_c('p',{staticClass:"time",style:({visibility: item.show ? '' : 'hidden'})},[_vm._v(_vm._s(item.time))]):_vm._e(),_c('div',{staticClass:"area"})])}):_vm._e(),_c('article',{staticClass:"todo-box"},_vm._l((_vm.groups),function(groupItem,gIndex){return _c('div',{key:gIndex,staticClass:"group",class:{
              'is-date-group': !_vm.isWeek
            },style:({
              top: groupItem.top + 'px',
              height: groupItem.height < 20 ? '20px' : groupItem.height + 'px',
            })},_vm._l((groupItem.children),function(child){return _c('section',{key:child.msg_type + 'msgType' +child.id,staticClass:"child",class:{
                'done': [2, 5].includes(child.status),
                'unfinish': ![2, 5].includes(child.status),
                'hover-showmore': groupItem.children.length > 4 && !_vm.isWeek,
                'is-week': _vm.isWeek
              },style:({
                height: child.height < 20 ? '20px' : child.height +'px',
                marginTop: child.top + 'px'
              }),on:{"click":function($event){return _vm.handleItemClick(child)}}},[_vm._v(" "+_vm._s(child.title)+" "),(_vm.isWeek)?_c('p',{staticClass:"week-large",class:{
                  'done': [2, 5].includes(child.status),
                  'unfinish': ![2, 5].includes(child.status),
                },style:({
                  height: child.height < 20 ? '20px' : child.height +'px',
                  marginTop: child.top + 'px',
                })},[_vm._v(_vm._s(child.title))]):_vm._e()])}),0)}),0)],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }