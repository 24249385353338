var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"week-view"},[_c('header',{staticClass:"header"},[_c('h3',{staticClass:"month"},[_vm._v(_vm._s(_vm._f("getChinaMonth")(_vm.month)))]),_c('ul',{staticClass:"week-days"},_vm._l((_vm.weekList),function(item,index){return _c('li',{key:index},[_c('span',{class:{
          'label': index === 5 || index === 6
        }},[_vm._v(_vm._s(item.date)+" ")]),_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.label)+" ")])])}),0)]),_c('article',{staticClass:"all-day"},[_c('p',{staticClass:"label"},[_vm._v("全天")]),_c('div',{staticClass:"all-content"},_vm._l((_vm.weekList),function(item,index){return _c('section',{key:index,staticClass:"day"},_vm._l((item.allDayList),function(dayItem){return _c('div',{key:dayItem.msg_type + 'msgType' + dayItem.id,staticClass:"item",class:[2, 5].includes(item.status) ? 'done' : 'unstart',on:{"click":function($event){return _vm.handleItemClick(dayItem)}}},[_vm._v(" "+_vm._s(dayItem.title)+" ")])}),0)}),0)]),_c('article',{ref:"boxMain",staticClass:"content"},[_c('div',{staticClass:"time-box"},_vm._l((_vm.timeList),function(item){return _c('li',{key:item.time,staticClass:"hour",class:{
          'is-target': item.isTarget,
          'on-hour': item.time.indexOf('00') !== -1
        },style:({
          top: item.isTarget ? (-1 * (60 - item.minutes) / 60) * 56 + 'px': 0
        }),attrs:{"id":item.time}},[_c('p',{staticClass:"time",style:({visibility: item.show ? '' : 'hidden'})},[_vm._v(_vm._s(item.time))]),_c('div',{staticClass:"area"})])}),0),_c('article',{staticClass:"week-box"},_vm._l((_vm.weekList),function(item,index){return _c('section',{key:index,staticClass:"week-item",class:{
          'weekend': index === 5 || index === 6
        }},[_c('DayView',{key:index,attrs:{"isFirstDay":index === 0,"isWeek":true,"todoList":item}})],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }